import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Layout from '../common/layouts'
import Seo from '../common/seo'

export default ({ data, pageContext }) => {
  const {posts = []} = pageContext
  return (
    <Layout>
      <Seo />
      <section className="mw7 center avenir">
        <h2 className="baskerville fw1 ph3 ph0-l">Blog</h2>
        {posts.length && posts.map(({featuredImage, title, slug, id, excerpt, author}) => (
          <article key={id} className="bt bb b--black-10">
            <Link className="db pv4 ph3 ph0-l no-underline black dim" to={`/blog/${slug}`}>
              <div className="flex flex-column flex-row-ns">
                <div className="pr3-ns mb4 mb0-ns w-100 w-40-ns">
                  {featuredImage && (
                    <Img
                      className="db"
                      fixed={featuredImage.localFile.childImageSharp.fixed}
                      alt={featuredImage.altText}
                    />
                  )}
                </div>
                <div className="w-100 w-60-ns pl3-ns">
                  <h1 className="f3 fw1 baskerville mt0 lh-title">{title}</h1>
                  <div dangerouslySetInnerHTML={{__html: excerpt.replace('<p>', '<p class="f6 f5-l lh-copy">')}}/>
        <p className="f6 lh-copy mv0">By {author.name}</p>
                </div>
              </div>
            </Link>
          </article>
        ))}
      </section>
    </Layout>
  )
}